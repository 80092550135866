.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
@keyframes rolling {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
.wrapper {
  font-family: PingFangSC-Regular, PingFang SC;
  color: #FFF;
  text-align: ceter;
  padding-top: 150px;
}
.title {
  font-size: 36px;
  font-weight: 600;
  color: #000000;
  text-align: center;
}
.banner {
  width: 100%;
  padding-top: 56.6%;
  background-image: url(https://mktv-in-cdn.mockuai.com/16678734374745355.jpg?x-oss-process=image/quality,q_80);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.banner-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: #FFF;
  text-align: center;
}
.banner-inner .title {
  margin: 26% 0 30px;
  font-size: 72px;
  color: #FFF;
}
.banner-inner .sub-title {
  font-size: 36px;
  margin-bottom: 20px;
}
.banner-inner .desc {
  font-size: 21px;
  font-weight: 400;
  opacity: 0.8;
}
.box {
  display: flex;
  align-items: center;
}
.box .icon {
  width: 28px;
  height: 28px;
  margin-right: 9px;
}
.box .text {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FF6600;
}
.box1 {
  width: 75%;
  min-width: 1300px;
  text-align: center;
  margin: 0 auto;
  color: #FFF;
}
.box1 .title {
  margin: 92px 0 57px;
}
.box1 .content {
  border-radius: 8px;
  background-image: url(https://mktv-in-cdn.mockuai.com/16678704617291237.jpg?x-oss-process=image/quality,q_80);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 20px 40px;
}
.box1 .content .txt {
  font-size: 30px;
  line-height: 42px;
  margin: 0 auto;
  padding: 50px 0 46px;
}
.box1 .content .user-list {
  display: flex;
}
.box1 .content .user-list .user-item {
  flex: 1;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 20px;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(8px);
}
.box1 .content .user-list .avatar {
  display: block;
  width: 62px;
  height: 62px;
}
.box1 .content .user-list .user-name {
  font-size: 20px;
  line-height: 28px;
  margin: 10px 0 5px 0;
}
.box1 .content .user-list .user-desc {
  font-size: 14px;
}
.box2 {
  margin-top: 140px;
  background-image: url(https://mktv-in-cdn.mockuai.com/16678704617261533.jpg?x-oss-process=image/quality,q_80);
  background-size: cover;
}
.box2 .title {
  padding: 104px 0 69px;
  text-align: center;
  color: #FFF;
}
.box2 .img1 {
  display: block;
  width: 75%;
  min-width: 1300px;
  margin: 0 auto;
}
.box3 {
  width: 75%;
  min-width: 1300px;
  margin: 0 auto;
}
.box3 .title {
  margin: 45px 0 35px;
}
.box3 .list {
  display: flex;
}
.box3 .list .item {
  flex: 1;
  padding-top: 16%;
  color: #FFF;
  background-image: url(https://mktv-in-cdn.mockuai.com/16678704617219861.jpg?x-oss-process=image/quality,q_80);
  background-size: cover;
  margin-right: 40px;
  border-radius: 4px;
  position: relative;
}
.box3 .list .item:last-child {
  margin-right: 0;
}
.box3 .list .item p {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
}
.box3 .contact-us {
  background-image: url(https://mktv-in-cdn.mockuai.com/16678704617244100.jpg?x-oss-process=image/quality,q_80);
  background-size: cover;
  padding-top: 30%;
  position: relative;
  margin: 55px 0 80px;
  border-radius: 16px;
}
.box3 .contact-us-inner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.box3 .contact-us-inner .txt {
  font-size: 24px;
  margin-top: 35px;
  font-weight: 600;
}
.goods-box {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 75%;
  min-width: 1300px;
  padding: 55px 0;
  margin: 0 auto;
}
.goods-box .logo {
  width: 5.208333333333334vw;
  height: 1.4583333333333333vw;
  display: block;
}
.goods-box .split {
  width: 1px;
  height: 3.65853659vw;
  margin: 0 2.08333333%;
  background-color: #fff;
  opacity: 0.5;
}
.goods-box .goods-swiper {
  position: relative;
  flex: 1;
  width: 87.43055556%;
  height: 4.87804878vw;
  overflow: hidden;
}
.goods-box .goods-swiper::before,
.goods-box .goods-swiper::after {
  content: "";
  position: absolute;
  z-index: 20;
  width: 2.9268292682926833vw;
  height: 100%;
}
.goods-box .goods-swiper::before {
  left: 0;
  background: linear-gradient(270deg, rgba(0, 9, 42, 0) 0%, #00092a 100%);
}
.goods-box .goods-swiper::after {
  right: 0;
  background: linear-gradient(270deg, #00092a 0%, rgba(0, 9, 42, 0) 100%);
}
.goods-box .goods-swiper .swiper-wrapper {
  transition-timing-function: linear !important;
}
.goods-box .goods-swiper .good-item {
  width: 4.87804878vw;
  height: 4.87804878vw;
  margin-right: 1.95121951vw;
  border-radius: 50%;
}
.goods-box .goods-swiper .good-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.goods-box .goods-swiper .goods-list {
  animation: rolling 40s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.goods-box .goods-swiper .goods-list.rolling {
  animation: rolling 27s linear infinite;
}
.goods-box .goods-swiper .goods-list:hover {
  animation-play-state: paused;
}
